import React from "react";
import Container from "../components/container";

const NotFoundPage = () => (
    <>
        <Container>
            <h1>De pagina werd niet gevonden</h1>
        </Container>
    </>
);

export default NotFoundPage;
